import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import UploadButtons from "./uploadButtons";
import VideoPlayer from "./videoPlayer";
import Button from "@material-ui/core/Button";
import { createVideoSignedUrl, editVideo, getVideo } from "services/api.service";

export default class UploadNeeded extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pollStatus: null,
      countdownSecond: 3,
      percentCompleted: 0,
      selectedVideoDeviceId: "loading",
      videoDevices: [],
    };
  }

  componentDidMount() {
    try {
      //if page restarts and status is then mediaURLUploaded then listen for updates
      if (this.props.status === "webMUploaded") {
        this.pollForUploadStatus(this.props.videoId);
      }
    } catch (err) {
      this.setState({ errorMessage: err.message });
    }
  }

  componentWillUnmount() {
    //make sure to end all streams
    if (this.stream) {
      this.stream.getTracks().forEach((track) => track.stop());
    }

    if (this.state.pollStatus) {
      clearInterval(this.state.pollStatus);
    }
  }

  pollForUploadStatus = (videoId) => {
    const intervalId = setInterval(async () => {
      const video = await getVideo(videoId);

      if (!video) {
        return;
      }

      const status = video?.status ?? "create";

      if (status === "error") {
        const errorMessage =
          "There was an error transcoding your video. Try removing or deleting this video and starting over.";
        this.props.videoUploadStatusChanged(
          status,
          null,
          null,
          errorMessage,
          null,
          null,
          null
        );
      } else {
        let { videoUrl, thumbnailUrl, gifUrl, audioUrl, phrases = null } = video;
        this.props.videoUploadStatusChanged(
          status,
          videoUrl,
          videoUrl,
          null,
          thumbnailUrl,
          gifUrl,
          audioUrl,
          phrases
        );
      }
    }, 5000);
    this.setState({ pollStatus: intervalId });
  };

  createRecording = async (e, newVideoDeviceId) => {
    try {
      //cancel any streams
      if (this.stream) this.stream.getTracks().forEach((track) => track.stop());

      //get camera devices. this will only run on first open since !newVideoDeviceId
      //if newVideoDeviceId exist, then createRecording was called after used changed video device on videoPlayer
      let tempDevices = [];
      //get devices so we can show them to user before the record. they might use 2nd or external camera
      let devices = await window.navigator.mediaDevices.enumerateDevices();
      devices.forEach((device) => {
        if (device.kind === "videoinput") tempDevices.push(device);
      });

      let deviceId;
      let preferredCameraDeviceId = window.localStorage.getItem(
        "preferredCameraDeviceId"
      );
      if (newVideoDeviceId) deviceId = newVideoDeviceId;
      else if (preferredCameraDeviceId) deviceId = preferredCameraDeviceId;
      else deviceId = tempDevices && tempDevices[0] && tempDevices[0].deviceId; //else just make first device the one being used to record

      let constraints = {
        audio: true,
        video: {
          video: { width: 10240, height: 4320 },
          //facingMode: 'user', was causing error for some users
          deviceId: deviceId ? deviceId : undefined,
        },
      };

      let stream = await window.navigator.mediaDevices.getUserMedia(constraints);
      // show it to user
      this.stream = stream;
      let playerRef = this.props.videoRef;

      // Older browsers don't support srcObject
      if ("srcObject" in playerRef) {
        playerRef.srcObject = stream;
      } else {
        playerRef.src = window.URL.createObjectURL(stream);
      }

      //added onloadedmetadata so doesn't start too early
      playerRef.onloadedmetadata = () => {
        if (stream) {
          playerRef.play();
        }
      };

      // init recording
      this.mediaRecorder = new MediaRecorder(stream);
      // init data storage for video chunks
      this.chunks = [];
      // listen for data from media recorder
      this.mediaRecorder.ondataavailable = (e) => {
        if (e.data && e.data.size > 0) {
          this.chunks.push(e.data);
        }
      };

      this.props.updateStatus("record", null);
      this.setState({
        selectedVideoDeviceId: newVideoDeviceId,
        videoDevices: tempDevices,
      });
    } catch (err) {
      console.error(err);
      console.error(err.message);
      this.props.updateStatus(
        "createError",
        "Recording is not supported in this browser. Please open this link in Chrome to record a video, upload an existing video file, or access this page from your smartphone." +
          err.toString()
      );
    }
  };

  startRecordingCountdown = (e) => {
    try {
      e.preventDefault();
      this.props.updateStatus("recordingCountdown", null);

      this.timer = setInterval(() => {
        let { countdownSecond } = this.state;
        if (countdownSecond <= 1) {
          this.startRecording();
          clearInterval(this.timer);
          this.setState({ countdownSecond: 3 });
        } else {
          this.setState({ countdownSecond: countdownSecond - 1 });
        }
      }, 1000);
    } catch (err) {
      console.error(err.message);
      this.props.updateStatus(
        "createError",
        "Error recording. Please upload an existing file."
      );
    }
  };

  startRecording = (e) => {
    try {
      if (e) e.preventDefault();
      // wipe old data chunks
      this.chunks = [];
      // start recorder with 10ms buffer
      this.mediaRecorder.start(1);
      // say that we're recording
      this.props.updateStatus("recording", null);
    } catch (err) {
      console.error(err.message);
      this.props.updateStatus(
        "createError",
        "Error recording. Please upload an existing file."
      );
    }
  };

  stopRecording = (e) => {
    try {
      e.preventDefault();
      // stop the recorder
      this.mediaRecorder.stop();
      this.stream.getTracks().forEach((track) => track.stop());
      const blob = new Blob(this.chunks, { type: "audio/*,video/*" });

      // generate video url from blob
      let playerRef = this.props.videoRef;

      //must make sure srcObject is null
      playerRef.srcObject = null;
      playerRef.src = URL.createObjectURL(blob);

      this.props.updateStatus("recorded", null);
    } catch (err) {
      console.error(err.message);
      this.props.updateStatus(
        "create",
        "Error recording. Please upload an existing file."
      );
    }
  };

  uploadRecording = () => {
    try {
      let blob = new Blob(this.chunks, { type: "audio/*,video/*" });
      this.uploadMedia(blob);
    } catch (err) {
      console.error(err.message);
      this.props.updateStatus("create", err.message);
    }
  };

  uploadFile = (event) => {
    const file = event && event.target && event.target.files && event.target.files[0];
    this.uploadMedia(file);
  };

  mediaUploadProgress = (progressEvent) => {
    try {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      this.setState({ percentCompleted });
    } catch (err) {
      this.setState({ percentCompleted: 0 });
    }
  };

  uploadMedia = async (file) => {
    try {
      let readUrl;
      //max is 500 mb
      //a minute of record time is about 5mb
      const maxFileSize = 700 * 1024 * 1024;
      if (file && file.size > maxFileSize)
        throw new Error(
          "File is too large, must be smaller than 700mb. Recordings must be less than 60 minutes. Please re-try with shorter file."
        );

      this.props.updateStatus("uploading", null);

      const result = await createVideoSignedUrl({ videoId: this.props.videoId });

      const presignedPost = result?.presignedPost;
      readUrl = result?.readUrl;
      const presignedPostFields = presignedPost?.fields;

      if (!presignedPost) throw new Error("Error creating upload url");

      var formData = new FormData();
      formData.append("acl", "public-read");

      for (const field in presignedPostFields) {
        formData.append(field, presignedPostFields[field]);
      }

      formData.append("file", file);

      let config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      await axios.post(presignedPost.url, formData, config);

      this.props.updateStatus("uploadedGoodToExit", null);

      const updates = {
        status: "webMUploaded",
        videoUrl: readUrl,
        speakerName: this.props.recorderName,
      };
      await editVideo(this.props.videoId, updates);

      this.props.videoUploadStatusChanged(
        "webMUploaded",
        readUrl,
        null,
        null,
        null,
        null,
        null,
        null
      );
      this.pollForUploadStatus(this.props.videoId);
    } catch (err) {
      let errorMessage = err?.response?.data?.message ?? err.message;
      console.error(errorMessage);
      this.props.updateStatus("create", errorMessage);
    }
  };

  deleteMediaUpload = () => {
    clearInterval(this.state.pollStatus);
    this.props.videoUploadStatusChanged(
      "create",
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
  };

  //this is called after a user hits delete then undo. we just bring back the old state from before user hit delete video button
  reviveDeletedVideoState = () => {
    let { status, webmURL, mediaURL, thumbnail, gif, audioSrc, captions } =
      this.props.deletedVideoState;
    this.props.videoUploadStatusChanged(
      status,
      webmURL,
      mediaURL,
      null,
      thumbnail,
      gif,
      audioSrc,
      captions
    );
  };

  render() {
    let fileUploadClass = this.props.hideUploadClass ? "" : "featureFileUploadBox";
    return (
      <div className={"row col-12 " + fileUploadClass}>
        {this.props.deletedVideoState && (
          <Button onClick={this.reviveDeletedVideoState}>
            <i className="material-icons" style={{ color: "black" }}>
              settings_backup_restore
            </i>
            Undo Delete
          </Button>
        )}

        <VideoPlayer
          {...this.state}
          {...this.props}
          passedSetState={this.props.setVideoRef}
          createRecording={this.createRecording}
        />

        <UploadButtons
          status={this.props.status}
          percentCompleted={this.state.percentCompleted}
          createRecording={this.createRecording}
          startRecordingCountdown={this.startRecordingCountdown}
          stopRecording={this.stopRecording}
          uploadFile={this.uploadFile}
          uploadRecording={this.uploadRecording}
          deleteMediaUpload={this.deleteMediaUpload}
        />
      </div>
    );
  }
}

UploadNeeded.propTypes = {
  accountId: PropTypes.string.isRequired,
  recorderName: PropTypes.string.isRequired,
  videoId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  videoRef: PropTypes.object,
  setVideoRef: PropTypes.func.isRequired,
  videoUploadStatusChanged: PropTypes.func.isRequired,
  updateStatus: PropTypes.func.isRequired,
  deletedVideoState: PropTypes.shape({
    status: PropTypes.string.isRequired,
    webmURL: PropTypes.string,
    mediaURL: PropTypes.string.isRequired,
    thumbnail: PropTypes.string.isRequired,
    gif: PropTypes.string.isRequired,
    audioSrc: PropTypes.string.isRequired,
    captions: PropTypes.object,
  }),
  hideUploadClass: PropTypes.bool,
};
