import React from "react";
import AddVideoButton from "./addVideoButton";
import RecordVideo from "./recordVideo";
import SearchVideo from "./search/searchVideo";
import RequestVideo from "./request/requestVideo";

export default function AddVideo(props) {
  let {
    addState,
    videoAdded,
    setAddState,
    noteTitle,
    videoIds,
    calledFromTask = false,
    currentTask,
    contactData = {},
    userInfo = {},
    forceShowAddVideoVsRecord = null,
  } = props;
  let recorderName = userInfo?.fullName ?? "";
  let usingiOS =
    !!window.navigator.platform && /iPad|iPhone|iPod/.test(window.navigator.platform);
  let mediaRecorderAvailable = window.MediaRecorder ? true : false;
  let isIOS =
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(window.navigator.platform) ||
    (window.navigator.userAgent.includes("Mac") && "ontouchend" in document);
  if (isIOS) mediaRecorderAvailable = false;

  if (addState === "record")
    return (
      <RecordVideo
        accountId={props.accountInfo.id}
        videoAdded={videoAdded}
        videoIds={videoIds}
        noteTitle={noteTitle}
        setAddState={setAddState}
        currentTask={currentTask}
        contactData={contactData}
        recorderName={recorderName}
      />
    );
  else if (addState === "search")
    return (
      <SearchVideo
        videos={props.videos}
        videoSelected={videoAdded}
        accountInfo={props.accountInfo}
        setAddState={setAddState}
      />
    );
  else if (addState === "request")
    return (
      <RequestVideo
        videoAdded={videoAdded}
        accountInfo={props.accountInfo}
        setAddState={setAddState}
        currentTask={currentTask}
      />
    );
  //upload is handled on AddVideoButton
  return (
    <>
      {!mediaRecorderAvailable && !usingiOS && (
        <div className="notificationMessageBox">
          <span>
            Recording is not supported in this browser. Open this page in Google Chrome,
            or visit from your smartphone.
          </span>
        </div>
      )}
      <AddVideoButton
        videoIds={videoIds}
        setAddState={setAddState}
        videoAdded={videoAdded}
        noteTitle={noteTitle}
        calledFromTask={calledFromTask}
        contactData={props.contactData}
        currentTask={currentTask}
        usingiOS={usingiOS}
        recorderName={recorderName}
        forceShowAddVideoVsRecord={forceShowAddVideoVsRecord}
        accountId={props.accountId}
      />
    </>
  );
}
