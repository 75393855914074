import React, { useState, useEffect } from "react";
import { LinearProgress, TextField } from "@material-ui/core";
import { authenticateGraduway } from "services/api.service";
import LoadingButton from "../../../../util/buttons/loadingbutton";

export default function Authenticate(props) {
  let [instanceURL, setInstanceURL] = useState("");
  let [apiKey, setApiKey] = useState("");
  let [platformId, setPlatformId] = useState("");
  let [platformName, setPlatformName] = useState("");
  let [state, setState] = useState({});
  let { integrationDetails, connectionInfo, accountInfo } = props;
  let { header, logoSrc, brandHex } = integrationDetails;

  useEffect(() => {
    let getConnectionInfo = async () => {
      try {
        const connectionInfo =
          props.accountInfo.integrations?.endpoint?.graduwayEngage?.connectionInfo;
        props.setConnectionInfo(connectionInfo);
      } catch (err) {
        setState({ errorMessage: err.message });
      }
    };

    getConnectionInfo();
  }, [accountInfo.accountUID, connectionInfo, props]);

  let saveApiToken = async () => {
    try {
      if (!instanceURL || !platformId || !platformName || !apiKey)
        throw new Error("Please add all four inputs");
      setState({ buttonState: "loading" });

      const postData = { apiKey, instanceURL, platformId, platformName };
      await authenticateGraduway(postData);

      props.setConnectionInfo({ dateConnected: Date.now() });
    } catch (err) {
      let errorMessage = err.data?.message || err.message;
      console.log(errorMessage);
      setState({ errorMessage });
    }
  };

  if (connectionInfo === "loading")
    return (
      <div className="col-12" style={{ padding: "20px" }}>
        {" "}
        <LinearProgress />
      </div>
    );

  return (
    <div className="row col-12">
      <div className="col-12 row">
        <div
          style={{
            width: "70px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <div
            style={{
              marginRight: "8px",
              width: "45px",
              height: "45px",
              backgroundColor: brandHex,
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <img src={logoSrc} width="50px" alt="logo" />
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <h1>{header}</h1>
          <span style={{ display: "flex", alignItems: "center" }}>
            <i
              className="material-icons"
              title="check icon"
              style={{ color: "red", fontSize: "25px", marginRight: "4px" }}>
              cancel
            </i>
            Your account is not connected.
          </span>
        </div>
      </div>

      <div className="col-12" style={{ marginTop: "25px" }}>
        Need your Graduway setup keys? Email your Graduway contact.
      </div>

      <div className="col-12" style={{ marginTop: "20px" }}>
        <TextField
          fullWidth={true}
          id="instanceURL"
          label="Graduway Instance URL"
          variant="outlined"
          value={instanceURL}
          onChange={(e) => setInstanceURL(e.target.value)}
        />
      </div>

      <div className="col-12" style={{ marginTop: "20px" }}>
        <TextField
          fullWidth={true}
          id="platformId"
          label="Graduway Platform Id"
          variant="outlined"
          value={platformId}
          onChange={(e) => setPlatformId(e.target.value)}
        />
      </div>

      <div className="col-12" style={{ marginTop: "20px" }}>
        <TextField
          fullWidth={true}
          id="platformName"
          label="Graduway Platform Name"
          variant="outlined"
          value={platformName}
          onChange={(e) => setPlatformName(e.target.value)}
        />
      </div>

      <div className="col-12" style={{ marginTop: "20px" }}>
        <TextField
          fullWidth={true}
          id="apiKey"
          label="Graduway API Key"
          variant="outlined"
          value={apiKey}
          onChange={(e) => setApiKey(e.target.value)}
          type="password"
        />
      </div>

      {state.errorMessage && (
        <div className="col-12 errorMessageBox">
          <b>{state.errorMessage}</b>
        </div>
      )}

      <div className="col-12" style={{ marginTop: "20px" }}>
        <LoadingButton
          variant="contained"
          color="primary"
          buttonState={state.buttonState}
          savedLabel="Connected!"
          label="Connect"
          clickedRegularButton={saveApiToken}
          clickedSavedButton={() => {
            setState({});
          }}
        />
      </div>
    </div>
  );
}
