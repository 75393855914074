import React, { useState } from "react";
import PropTypes from "prop-types";
import LoadingButton from "../../../../../util/buttons/loadingbutton";
import arrayToCsv from "../../../../../util/contacts/arrayToCsv";
import { DialogActions, DialogContent, Dialog, IconButton } from "@material-ui/core";
import { getAccountUser, getTasks } from "services/api.service";
import defaultTaskProperties from "components/util/data/defaultTaskProperties.json";

export default function Download(props) {
  let [state, setState] = useState({});
  const allowedTaskProperties = {
    ...defaultTaskProperties,
    ...props.accountInfo.contactProperties,
  };

  let doWork = async (e) => {
    try {
      e.preventDefault();

      setState({ buttonState: "loading" });

      let tasks = props.selectedItems;

      if (props.allSelected) {
        const result = await getTasks(props.assignedToUserUid, "UNSENT", { limit: 0 });
        tasks = result.rows;
      }

      const csvRows = [];
      for (const task of tasks) {
        for (const taskContact of task.taskContacts) {
          const newTaskObject = {};

          for (const key in allowedTaskProperties) {
            const current = allowedTaskProperties[key].columnHeader;

            if (task[current]) {
              newTaskObject[current] = task[current];
            } else if (taskContact.contact?.[current]) {
              newTaskObject[current] = taskContact.contact?.[current];
            } else {
              newTaskObject[current] = "";
            }
          }

          if (task.assignedUserId) {
            const assignee = props.users.find((user) => user.id === task.assignedUserId);
            newTaskObject.assigneeEmail = assignee?.email;
          }
          newTaskObject.groupTaskId = task.taskContacts.length > 1 ? task.id : "";

          csvRows.push(newTaskObject);
        }
      }

      arrayToCsv(csvRows);
      setState({ buttonState: "saved" });
    } catch (err) {
      console.error(err.message);
      setState({ buttonState: "regular", errorMessage: err.message });
    }
  };

  let { buttonState, errorMessage } = state;

  return (
    <Dialog
      fullScreen={props.fullScreen}
      open={props.dialogOpen}
      onClose={props.closeDialog}
      aria-labelledby="delete task dialog">
      <DialogContent
        style={{ minHeight: "500px", width: props.fullScreen ? "100%" : "600px" }}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <span style={{ fontSize: "18px" }}>Download Task</span>
          </div>
          <div style={{ width: "48px" }}>
            <IconButton aria-label="close" onClick={props.closeDialog}>
              <i className="material-icons">close</i>
            </IconButton>
          </div>
        </div>

        <div className="col-12">
          <p>Download selected tasks ({props.selectedCount}) to a CSV file?</p>
        </div>

        {errorMessage && (
          <div className="errorMessageBox">
            <b>{errorMessage}</b>
          </div>
        )}
      </DialogContent>

      <DialogActions style={{ padding: "12px", backgroundColor: "hsl(220, 12%, 95%)" }}>
        <LoadingButton
          variant="contained"
          color="primary"
          buttonState={buttonState}
          savedLabel="Downloaded"
          label="Download"
          clickedRegularButton={doWork}
          clickedSavedButton={props.closeDialog}
        />
      </DialogActions>
    </Dialog>
  );
}

Download.propTypes = {
  accountInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firebaseId: PropTypes.string,
  }).isRequired,
  assignedToUserUID: PropTypes.string.isRequired,
  selectedItems: PropTypes.array.isRequired,
  allSelected: PropTypes.bool.isRequired,
  users: PropTypes.array.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  selectedCount: PropTypes.number.isRequired,
};
