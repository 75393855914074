import React, { useState, useRef } from "react";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  MenuItem,
  MenuList,
  Popper,
} from "@material-ui/core";
import { useRecording } from "../../../../util/hooks/video/useCamera";
import LoadingPopover from "../../../../util/loadingPopover";

export default function AddVideoButton(props) {
  let { videoIds = [], calledFromTask, forceShowAddVideoVsRecord = null } = props;
  let variant = videoIds.length > 0 ? "outlined" : "contained";
  let color = videoIds.length > 0 ? "default" : "primary";
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  let { usingiOS = false, recorderName = "" } = props;
  recorderName = usingiOS ? recorderName : null; //for uploads, only send recorder name if uploading from iphone/ipad. otherwise they are just uploading file which shouldn't have recorder name
  const [
    recordStatus,
    recordingCountdown,
    startRecordingCountdown,
    stopRecording,
    finalVideoBlob,
    reRecord,
    createVideoObject,
    uploadMedia,
    uploadedPercent,
    recordErrorMessage,
  ] = useRecording(null, null, recorderName, props.accountId);
  const [state, setState] = useState({});
  const [uploadFileUploading, setUploadFileUploading] = useState(false);

  const handleMenuItemClick = (id) => {
    setOpen(false);
    if (id === "loading") return;
    props.setAddState(id);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  let uploadVideoFile = async (event) => {
    try {
      //first get title for record
      const file = event && event.target && event.target.files && event.target.files[0];
      let { currentTask = {}, contactData = {} } = props;
      let { taskId = null, comments = "New Video" } = currentTask;
      let videoTitle = file.name;
      if (taskId) {
        //when we fix contacts, somehow add contacts name to vid title
        let taskVidTitle =
          contactData && contactData.label ? contactData.label : comments;
        videoTitle = "Task: " + taskVidTitle;
      }

      //get videoId if needed
      setUploadFileUploading(true);
      console.log(taskId);

      let uploadData = await createVideoObject(videoTitle, taskId);
      let videoId = uploadData && uploadData.videoId;

      //upload
      await uploadMedia(file, videoId);

      //pass new video back
      props.videoAdded({ id: videoId, status: "webMUploaded" });
      props.setAddState(null);
      setUploadFileUploading(false);
    } catch (err) {
      console.error(err.message);
      setUploadFileUploading(false);
      setState({ errorMessage: err.message });
    }
  };

  let mediaRecorderAvailable = window.MediaRecorder ? true : false;
  let isIOS =
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(window.navigator.platform) ||
    (window.navigator.userAgent.includes("Mac") && "ontouchend" in document);
  if (isIOS) mediaRecorderAvailable = false;

  let menuOptions = [];

  //if MediaRecorder not exist, then we have to let user upload a file, can't use webrecord
  if (mediaRecorderAvailable) {
    menuOptions = [
      { id: "record", label: "Record Now" },
      { id: "search", label: "Search Existing Vids" },
      { id: "request", label: "Request New Video" },
      { id: "upload", label: "Upload File" },
    ];
  } else {
    menuOptions = [
      { id: "upload", label: "Record/upload" },
      { id: "search", label: "Search Existing Vids" },
      { id: "request", label: "Request New Video" },
    ];
  }

  return (
    <div className={calledFromTask ? "row col-12" : "row col-12 justify-content-center"}>
      {uploadFileUploading && <LoadingPopover uploadedPercent={uploadedPercent} />}

      {state.errorMessage && (
        <div className="errorMessageBox">
          <b>{state.errorMessage}</b>
        </div>
      )}

      <input
        type="file"
        accept="video/mp4,video/x-m4v,video/*"
        style={{ display: "none" }}
        id="button-file"
        onClick={(e) => (e.target.value = null)}
        onChange={uploadVideoFile}
      />

      <ButtonGroup
        variant={variant}
        color={color}
        ref={anchorRef}
        aria-label="split button">
        {videoIds.length > 0 || forceShowAddVideoVsRecord ? (
          <Button onClick={handleToggle}>
            <i className="material-icons">add</i>Add Video
          </Button>
        ) : mediaRecorderAvailable ? (
          <Button onClick={() => props.setAddState("record")}>Record Now</Button>
        ) : (
          <label htmlFor="button-file">
            <Button variant={variant} color={color} component="span">
              Record/Upload
            </Button>
          </label>
        )}
        <Button
          color={color}
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}>
          <i className="material-icons">arrow_drop_down</i>
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        style={{ zIndex: "1301" }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}>
            <Paper style={{ zIndex: "1302" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {menuOptions.map((current) => {
                    let { label, id } = current;
                    if (id === "upload") {
                      return (
                        <label htmlFor="button-file" key={id}>
                          <MenuItem component="span">{label}</MenuItem>
                        </label>
                      );
                    }
                    return (
                      <MenuItem key={id} onClick={() => handleMenuItemClick(id)}>
                        {label}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
