import React, { useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Checkbox, TextField } from "@material-ui/core";
import { manageIntegrations } from "services/api.service";

export default function Exclude(props) {
  let { source, fetchEvent, excludeKey, title = "", selectedExcludes = {} } = props; //selectedExcludes is all excludes for event ie - opportunityWon
  let selectedExcludesForCurrentType = selectedExcludes[excludeKey]
    ? selectedExcludes[excludeKey]
    : {}; //selectedExcludesForCurrentType is only ones selected for key ( ie - campaigns, funds, etc)
  let selectedExcludesForCurrentTypeKeys = Object.keys(selectedExcludesForCurrentType)
    ? Object.keys(selectedExcludesForCurrentType)
    : [];
  let tempDefaultValues = selectedExcludesForCurrentTypeKeys.map((id) => {
    let current = selectedExcludesForCurrentType[id];
    return { id, label: current.label };
  });

  let [state, setState] = useState({}); //when select is open we change this to loading
  let [defaultValues, setDefaultValues] = useState(tempDefaultValues);

  //if options are passsed in no need to fetch
  let {
    options: initialOptions = [],
    optionsFetchState: initialOptionsFetchState = null,
  } = props;
  let [options, setOptions] = useState(initialOptions);
  let [optionsFetchState, setOptionsFetchState] = useState(initialOptionsFetchState);

  let selectOpened = async () => {
    if (optionsFetchState) return;

    //get data
    try {
      setOptionsFetchState("loading");
      const results = await manageIntegrations(
        { source: source, accountId: props.accountInfo.id },
        { event: fetchEvent }
      );
      setOptions(results?.results || results || []);
      setOptionsFetchState("fetched");
    } catch (err) {
      let errorMessage = err.data?.message || err.message;
      console.error(errorMessage);
      setState({ errorMessage });
      setOptionsFetchState("fetched");
    }
  };

  const handleChange = async (event, inputValue, reason) => {
    //inputValue is always going to be current selected values on input
    //therefore we just reformat the inputValue and send it back to selectedExcludes
    let tempSelectedExcludes = { ...selectedExcludes };
    let tempSelectedExcludesForCurrentType = {};
    inputValue.forEach((current) => {
      tempSelectedExcludesForCurrentType[current.id] = current;
    });

    tempSelectedExcludes[excludeKey] = tempSelectedExcludesForCurrentType;
    props.setSelectedExcludes(tempSelectedExcludes);
  };

  return (
    <div className="row col-12" style={{ marginTop: "25px" }}>
      <Autocomplete
        multiple
        fullWidth
        id={"exclude-" + title}
        selectOnFocus
        defaultValue={defaultValues}
        onOpen={selectOpened}
        loading={optionsFetchState === "loading"}
        options={options}
        onChange={handleChange}
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox checked={Boolean(selectedExcludesForCurrentType[option.id])} />
            {option.label}
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={"Exclude " + title}
            error={Boolean(state.errorMessage)}
            helperText={state.errorMessage}
          />
        )}
      />
    </div>
  );
}
