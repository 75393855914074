import React, { useState } from "react";
import Authenticate from "./authenticate";
import DeAuthenticateDialog from "./deAuthenticateDialog";
import GiftAdded from "./actions/giftAdded";
import TaskSent from "./triggers/taskSent";
import NoteWatched from "./triggers/noteWatched";
import SaveReply from "./triggers/saveReply";
import ImportContacts from "./contacts/importContacts";
import UnsubscribeAndStopText from "./triggers/unsubscribeAndStopText";
import QuestionToLink from "../../../../util/explainers/questionToLink";

export default function Blackbaud(props) {
  let { integrationDetails, data = {} } = props;
  let { events = {} } = data; //events are giftAdded and contactAddedUpdated
  let [connectionInfo, setConnectionInfo] = useState(props.data?.connectionInfo || null);
  let { header, logoSrc, brandHex } = integrationDetails;

  if (!connectionInfo || connectionInfo === "loading")
    return (
      <Authenticate
        connectionInfo={connectionInfo}
        setConnectionInfo={setConnectionInfo}
        {...props}
      />
    );

  const failureDate = connectionInfo.refreshTokenLastFailureDate
    ? new Date(connectionInfo.refreshTokenLastFailureDate).toLocaleDateString()
    : "";

  const status = failureDate
    ? { icon: "cancel", color: "red" }
    : { icon: "check_circle", color: "green" };

  return (
    <div className="col-12 row">
      <div
        style={{
          width: "70px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <div
          style={{
            marginRight: "8px",
            width: "45px",
            height: "45px",
            backgroundColor: brandHex,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <img src={logoSrc} width="50px" alt="logo" />
        </div>
      </div>
      <div style={{ flex: 1 }}>
        <div className="row col-12 align-items-center">
          <h1>{header}</h1>
          <QuestionToLink id="blackbaudIntegration" desc="Blackbaud Integration" />
        </div>

        <span style={{ display: "flex", alignItems: "center" }}>
          <i
            className="material-icons"
            title="check icon"
            style={{ color: status.color, fontSize: "25px", marginRight: "4px" }}>
            {status.icon}
          </i>
          {connectionInfo.legal_entity_name} is connected
          {failureDate && `, but failed on ${failureDate}. Please reconnect your account`}
          .
          <DeAuthenticateDialog {...props} />
        </span>
      </div>

      <GiftAdded {...props} eventData={events?.giftAdded} />
      <ImportContacts {...props} eventData={events?.importContacts} />
      <TaskSent {...props} eventData={events?.taskSent} />
      <SaveReply {...props} eventData={events?.reply} />
      <NoteWatched {...props} eventData={events?.noteWatched} />
      <UnsubscribeAndStopText {...props} eventData={events?.unsubscribeAndStopText} />

      <div className="col-12" style={{ height: "100px" }} />
    </div>
  );
}
