import React from "react";
import { FormControl, InputLabel, Select } from "@material-ui/core";

export default function VideoPlayer(props) {
  let { status, videoDevices, selectedVideoDeviceLabel } = props;
  let videoPlayerDimensions = {
    width: "100%",
    maxWidth: 400,
    borderRadius: "20px",
    backgroundColor: "#ddd",
  };

  const wrapperStyle = {
    paddingBottom: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  let changeVideoDevice = (e) => {
    let newVideoDeviceId = e.target.value;
    props.createRecording(null, newVideoDeviceId);
  };

  //then decide which buttons to show
  if (status === "requested" || status === "create") {
    return (
      <div className="col-12" style={wrapperStyle}>
        <video
          controls
          style={{ display: "none" }}
          ref={props.passedSetState}
          muted
          id="mediaPlayer">
          <p>Recording not available.</p>
        </video>
      </div>
    );
  } else if (status === "createError") {
    return null;
  } else if (status === "record") {
    return (
      <>
        <div className="col-12" style={wrapperStyle}>
          <video
            style={videoPlayerDimensions}
            ref={props.passedSetState}
            muted
            id="mediaPlayer">
            <p>Recording not available.</p>
          </video>
        </div>
        {videoDevices.length > 1 && (
          <div className="col-12" style={wrapperStyle}>
            <FormControl style={{ width: "200px" }}>
              <InputLabel htmlFor="selectedVideoDevice">Select Camera</InputLabel>
              <Select
                value={selectedVideoDeviceLabel}
                native
                onChange={changeVideoDevice}
                inputProps={{
                  name: "selectedVideoDeviceLabel",
                  id: "selectedVideoDeviceLabel",
                }}>
                {videoDevices.map((current) => {
                  let label = current.label ? current.label : current.deviceId;
                  return (
                    <option value={current.deviceId} key={current.deviceId}>
                      {label}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        )}
      </>
    );
  } else if (status === "recordingCountdown") {
    return (
      <div className="col-12" style={wrapperStyle}>
        <video
          style={videoPlayerDimensions}
          ref={props.passedSetState}
          muted
          id="mediaPlayer">
          <p>Recording not available.</p>
        </video>
        <div
          style={{
            position: "absolute",
            top: "70px",
            bottom: "0pm",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <div style={{ maxWidth: "400px" }}>
            <p style={{ fontSize: "100px", color: "white" }}>{props.countdownSecond}</p>
          </div>
        </div>
      </div>
    );
  } else if (status === "recording") {
    return (
      <div className="col-12" style={wrapperStyle}>
        <video
          style={videoPlayerDimensions}
          ref={props.passedSetState}
          muted
          id="mediaPlayer">
          <p>Recording not available.</p>
        </video>
      </div>
    );
  } else if (status === "recorded") {
    return (
      <div className="col-12" style={wrapperStyle}>
        <video
          controls
          style={videoPlayerDimensions}
          ref={props.passedSetState}
          id="mediaPlayer">
          <p>Recording not available.</p>
        </video>
      </div>
    );
  } else if (
    status === "uploading" ||
    status === "webMUploaded" ||
    status === "uploadedGoodToExit"
  ) {
    return (
      <div className="col-12" style={wrapperStyle}>
        <video style={{ display: "none" }} ref={props.passedSetState} id="mediaPlayer">
          <p>Recording not available.</p>
        </video>
      </div>
    );
  } else {
    let mediaURL = props.mediaURL;

    return (
      <div className="col-12" style={wrapperStyle}>
        <video
          controls
          style={videoPlayerDimensions}
          src={mediaURL}
          id="mediaPlayer"
          ref={props.passedSetState}>
          <p>Recording not available.</p>
        </video>
      </div>
    );
  }
}
