import React, { useState } from "react";
import { IconButton, Dialog, DialogContent, DialogActions } from "@material-ui/core";
import LoadingButton from "../../../../util/buttons/loadingbutton";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import AutoSuggestInput from "../../../../util/search/autoSuggestInput";
import PreviewOrEmbedByContact from "../../../../util/shareOptions/previewOrEmbedByContact";
import { sendNote } from "services/api.service";

function SendTestDialog(props) {
  let {
    accountInfo,
    userUID,
    noteId,
    shareViaText,
    shareViaEmail,
    videoIds,
    noteIsLoading,
    contacts,
    resendActive,
  } = props;
  let [dialogOpen, setDialogOpen] = useState(false);
  let [searchContact, setSearchContact] = useState("");
  let [contact, setContact] = useState(null);
  let [state, setState] = useState({});

  let saveAndSendTest = async () => {
    try {
      if (!contact) throw new Error("No contact selected. Select an existing contact.");
      if (!videoIds)
        throw new Error("No video. You must select a video before sending a note.");
      if (videoIds.length === 0)
        throw new Error("No video. You must select a video before sending a note.");
      if (!shareViaText && !shareViaEmail)
        throw new Error(
          "You must select either send email or send text under the 'Send To' section."
        );

      setState({ buttonState: "loading" });
      let gvSendId = "t_sid_" + crypto.randomUUID();
      let sendObject = {
        noteId,
        gvSendId,
        status: "processing",
        calledFrom: "editNote",
        contactIds: [contact.id],
        type: "sendNote",
        emailProvider: "default",
        sendTest: true,
      };
      await sendNote(sendObject);
      setState({ buttonState: "saved" });
      setDialogOpen(false);
      props.setTabSelected("history");
    } catch (err) {
      let errorMessage = err.data?.message || err.message;
      console.error(errorMessage);
      setState({ errorMessage });
    }
  };

  let addContactFromSeach = (contact) => {
    setContact(contact);
    setState({ buttonState: "regular" });
  };

  let clickedSavedButtonAfterSend = () => {
    setState({ buttonState: "regular" });
    setDialogOpen(false);
  };

  let closeDialog = () => {
    setState({ buttonState: "regular" });
    setDialogOpen(false);
  };

  //fullScreen is created from withMobileDialog
  let { noteVideos } = props;
  let noteVideosKeys =
    noteVideos && Object.keys(noteVideos) ? Object.keys(noteVideos) : [];
  let firstVidId = noteVideosKeys[0];
  let videoData =
    noteVideos && noteVideos[firstVidId] && noteVideos[firstVidId].video
      ? noteVideos[firstVidId].video
      : null;
  let {
    firstName = "",
    lastName = "",
    email = "",
    textOptIn = false,
  } = contact ? contact : {};

  return (
    <div>
      <span style={{ marginRight: "10px" }}>
        <LoadingButton
          variant="contained"
          color="default"
          buttonState={noteIsLoading ? "loading" : "regular"}
          savedLabel={"Sent!"}
          label="Send Test"
          clickedRegularButton={() => setDialogOpen(true)}
          clickedSavedButton={() => {
            return null;
          }}
        />
      </span>

      {videoData && (
        <PreviewOrEmbedByContact
          type="embed"
          title="Embed Video By Contact"
          noteId={noteId}
          accountInfo={accountInfo}
          taskContacts={contacts}
          videoData={videoData}
          videoId={firstVidId}
          userUID={userUID}
        />
      )}

      <PreviewOrEmbedByContact
        type="preview"
        title="Preview By Contact"
        noteId={noteId}
        accountInfo={accountInfo}
        taskContacts={contacts}
      />

      <Dialog
        fullScreen={props.fullScreen}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="send test dialog">
        <DialogContent
          style={{
            height: props.fullScreen ? "auto" : "550px",
            width: props.fullScreen ? "auto" : "600px",
          }}>
          <div className="col-12" style={{ display: "flex" }}>
            <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
              <span style={{ fontSize: "18px" }}>Send Test To A Contact</span>
            </div>
            <div style={{ width: "48px" }}>
              <IconButton aria-label="close" onClick={() => setDialogOpen(false)}>
                <i className="material-icons">close</i>
              </IconButton>
            </div>
          </div>

          <div
            className="col-12"
            style={{ display: "flex", alignItems: "center", marginTop: "25px" }}>
            {shareViaEmail ? (
              <i
                className="material-icons"
                style={{ color: "green", marginRight: "3px" }}>
                task_alt
              </i>
            ) : (
              <i className="material-icons" style={{ color: "grey", marginRight: "3px" }}>
                highlight_off
              </i>
            )}
            Send email
          </div>

          <div
            className="col-12"
            style={{ display: "flex", alignItems: "center", marginTop: "8px" }}>
            {shareViaText ? (
              <i
                className="material-icons"
                style={{ color: "green", marginRight: "3px" }}>
                task_alt
              </i>
            ) : (
              <i className="material-icons" style={{ color: "grey", marginRight: "3px" }}>
                highlight_off
              </i>
            )}
            Send text
          </div>

          <div
            className="col-12"
            style={{ display: "flex", alignItems: "center", marginTop: "8px" }}>
            {resendActive ? (
              <i
                className="material-icons"
                style={{ color: "green", marginRight: "3px" }}>
                task_alt
              </i>
            ) : (
              <i className="material-icons" style={{ color: "grey", marginRight: "3px" }}>
                highlight_off
              </i>
            )}
            Resend active{" "}
            {resendActive && " (resend test will send 10 seconds after primary test)"}
          </div>

          {!contact && (
            <div className="col-12" style={{ margin: "15px 0px 25px 0px" }}>
              <AutoSuggestInput
                type="searchContact"
                index="contacts"
                searchContact={searchContact}
                accountInfo={accountInfo}
                passedSetState={(state, value) => setSearchContact(value)}
                addContactFromSeach={(contact) => addContactFromSeach(contact)}
              />
            </div>
          )}

          {contact && (
            <div
              className="col-12"
              style={{
                display: "flex",
                backgroundColor: "#ededed",
                border: "1px #ddd solid",
                borderRadius: "15px",
                padding: "20px",
                marginTop: "20px",
              }}>
              <div style={{ flex: 1 }}>
                <span style={{ fontWeight: "bold" }}>
                  {firstName} {lastName}
                </span>
                <br />
                <span>{email}</span>
                <br />
                {contact.textOptIn && <span>Text Opt-In &#x2714;</span>}
              </div>

              <div style={{ width: "50px" }}>
                <IconButton
                  onClick={() => setContact(null)}
                  color="default"
                  aria-label="remove contact">
                  <i className="material-icons">close</i>
                </IconButton>
              </div>
            </div>
          )}

          {shareViaText && contact && !textOptIn && (
            <div style={{ marginTop: "20px" }}>
              Warning: Send text message is selected, but contact has not opted-in to
              text. Therefore, Gratavid will not send a test text.
            </div>
          )}

          {state.errorMessage && (
            <div className="col-12 errorMessageBox">
              <b>{state.errorMessage}</b>
            </div>
          )}
        </DialogContent>
        <DialogActions style={{ padding: "12px", backgroundColor: "hsl(220, 12%, 95%)" }}>
          <LoadingButton
            variant="contained"
            color="primary"
            disabled={contact ? false : true}
            buttonState={state.buttonState}
            savedLabel={"Test Sent!"}
            label={"Send Test"}
            clickedRegularButton={() => saveAndSendTest()}
            clickedSavedButton={() => clickedSavedButtonAfterSend()}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withMobileDialog()(SendTestDialog);
