import React, { useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Checkbox, TextField, Chip, FormControl } from "@material-ui/core";

export default function SelectList(props) {
  let {
    label = "",
    selectedItems = [],
    options,
    variant = "filled",
    width = "50%",
  } = props; //selectedExcludes is all excludes for event ie - opportunityWon

  let selectedItemsKeys = Object.keys(selectedItems);
  let tempDefaultValues = selectedItemsKeys.map((id) => {
    let current = selectedItems[id];
    return { id, label: current.label };
  });
  let [defaultValue, setDefaultValue] = useState(tempDefaultValues);

  //Boolean(selectedItems[option.id])

  return (
    <FormControl style={{ margin: "5px 0px", width }}>
      <Autocomplete
        multiple
        selectOnFocus
        id={"select-" + label}
        defaultValue={defaultValue}
        options={options}
        onChange={(event, inputValue) => props.handleChange(inputValue)}
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => value.id === option.id}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox checked={selected} />
            {option.label}
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField {...params} variant={variant} label={label} />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant={variant === "filled" ? "outlined" : "filled"}
              label={option.label}
              size="small"
              {...getTagProps({ index })}
            />
          ))
        }
      />
    </FormControl>
  );
}
