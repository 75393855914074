import React from "react";
import LoadingCircleSVG from "./svg/loadingCircleSVG";

export default function LoadingPopover(props) {
  let {
    logo = "https://gratavid.com/static/img/icon_padded.svg",
    uploadedPercent = null,
  } = props;

  return (
    <div
      style={{
        position: "fixed",
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "default",
        backgroundColor: "rgba(0,0,0,0.5)",
        zIndex: 1201,
      }}>
      <div className="story" style={{ opacity: 1 }}>
        <img src={logo} alt=" loading logo" style={{ backgroundColor: "white" }} />
        <LoadingCircleSVG />
      </div>
      <div
        style={{
          marginTop: "110px",
          height: "12px",
          color: "#fff",
          WebkitFilter: "drop-shadow(0 6px 22px rgba(0,0,0,.3))",
          filter: "drop-shadow(0 6px 22px rgba(0,0,0,.3))",
        }}>
        <p style={{ fontSize: "15px" }}>
          Please wait. Uploading...{uploadedPercent ? "(" + uploadedPercent + "%)" : null}
        </p>
      </div>
    </div>
  );
}
