import React, { useRef, useState } from "react";
import { useCamera, useRecording } from "../../../../util/hooks/video/useCamera";
import {
  Button,
  FormControl,
  LinearProgress,
  InputLabel,
  Select,
} from "@material-ui/core";
import LoadingPopover from "../../../../util/loadingPopover";

export default function RecordVideo(props) {
  const vidRef = useRef();
  const [state, setState] = useState({});
  const [uploadFileUploading, setUploadFileUploading] = useState(false);
  const [
    video,
    videoErrorMessage,
    videoDevices,
    selectedVideoDeviceId,
    setSelectedVideoDeviceId,
  ] = useCamera(vidRef);
  let { recorderName = "" } = props;
  const [
    recordStatus,
    recordingCountdown,
    startRecordingCountdown,
    stopRecording,
    finalVideoBlob,
    reRecord,
    createVideoObject,
    uploadMedia,
    uploadedPercent,
    recordErrorMessage,
  ] = useRecording(video, selectedVideoDeviceId, recorderName, props.accountId);

  let changeVideoDevice = async (e) => {
    try {
      let newVideoDeviceId = e.target.value;

      //set preferredCameraDeviceId to local storage to rememer it
      localStorage.setItem("preferredCameraDeviceId", newVideoDeviceId);

      //this will trigger new camera initializeCamera
      setSelectedVideoDeviceId(newVideoDeviceId);
    } catch (err) {
      console.error(err.message);
      setState({
        errorMessage:
          "We are sorry. Recording is not supported in this browser. Please open this link in Chrome to record a video, upload an existing video file, or access this page from your smartphone. " +
          err.message,
      });
    }
  };

  let uploadVideoBlob = async (file) => {
    try {
      //first get title for record
      let { currentTask = {}, noteTitle = "New Video", contactData = {} } = props;
      let { comments = "Say thanks", id } = currentTask;
      let videoTitle = noteTitle;
      if (id) {
        var trimmedComments = comments.substring(0, 100); //if title is comments cap at 100 charactres
        videoTitle = "Task: " + trimmedComments;
        if (contactData && contactData.label) {
          videoTitle = "Video for " + contactData.label;
          if (recorderName) videoTitle += " by " + recorderName;
        }
      }

      //get videoId if needed
      setUploadFileUploading(true);

      let uploadData = await createVideoObject(videoTitle, !!currentTask?.id);
      let videoId = uploadData && uploadData.videoId;

      //upload
      await uploadMedia(file, videoId);

      //pass new video back
      props.videoAdded({ id: videoId, status: "webMUploaded" });
      setUploadFileUploading(false);
      props.setAddState(null);
    } catch (err) {
      console.error(err.message);
      setUploadFileUploading(false);
      setState({ errorMessage: err.message });
    }
  };

  let videoRecord = finalVideoBlob ? true : false;
  let vidMuted = finalVideoBlob ? false : true;

  return (
    <div className="row col-12 pagePadding">
      {uploadFileUploading && <LoadingPopover uploadedPercent={uploadedPercent} />}

      {recordErrorMessage && (
        <div className="errorMessageBox">
          <b>{recordErrorMessage}</b>
        </div>
      )}

      {videoErrorMessage && (
        <div className="errorMessageBox">
          <b>{videoErrorMessage}</b>
        </div>
      )}

      {state.errorMessage && (
        <div className="errorMessageBox">
          <b>{state.errorMessage}</b>
        </div>
      )}

      {props.currentTask?.taskVideos?.length > 1 && (
        <p className="greyDesc" style={{ paddingBottom: "10px" }}>
          Record a new video.
        </p>
      )}

      <div
        className="col-12"
        style={{ paddingBottom: "20px", display: "flex", alignItems: "center" }}>
        <video
          style={{
            width: "100%",
            maxWidth: "350px",
            objectFit: "cover",
            borderRadius: "14px",
            backgroundColor: "#ddd",
          }}
          ref={vidRef}
          id="mediaPlayer"
          playsInline={true}
          muted={vidMuted}
          controls={videoRecord}>
          <p>Recording not available.</p>
        </video>
      </div>

      {recordStatus === "prerecord" && (
        <>
          {videoDevices.length > 1 && (
            <div className="col-12" style={{ paddingBottom: "20px", display: "flex" }}>
              <FormControl style={{ width: "200px" }}>
                <InputLabel htmlFor="selectedVideoDevice">Select Camera</InputLabel>
                <Select
                  value={selectedVideoDeviceId}
                  native
                  onChange={changeVideoDevice}
                  inputProps={{
                    name: "selectedVideoDeviceId",
                    id: "selectedVideoDeviceId",
                  }}>
                  {videoDevices.map((current) => {
                    let label = current.label ? current.label : current.deviceId;
                    return (
                      <option value={current.deviceId} key={current.deviceId}>
                        {label}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          )}

          <div className="col-12" style={{ display: "flex", paddingBottom: "10px" }}>
            <div className="align-items-center" style={{ width: "25px" }}>
              <button className="notRecordingButton" onClick={startRecordingCountdown} />
            </div>
            <div style={{ flex: 1 }}>
              <button
                className="trappbtn btn-green"
                onClick={startRecordingCountdown}
                style={{ width: "175px" }}>
                <i className="material-icons" style={{ color: "white" }}>
                  videocam
                </i>
                <span style={{ marginLeft: "4px", fontSize: "16px" }}>Start Record</span>
              </button>
            </div>
          </div>
          <div style={{ padding: "15px 0px" }}>
            or
            <Button
              onClick={() => props.setAddState(null)}
              style={{ textTransform: "none", marginBottom: "5px", marginLeft: "15px" }}
              variant="outlined"
              size="small">
              Cancel
            </Button>
          </div>
        </>
      )}

      {recordStatus === "recording" && (
        <div className="col-12" style={{ display: "flex", paddingBottom: "10px" }}>
          <div className="align-items-center" style={{ width: "25px" }}>
            <button className="recordingButton" onClick={stopRecording} />
          </div>
          <div style={{ flex: 1 }}>
            <button
              className="trappbtn btn-red"
              onClick={stopRecording}
              style={{ width: "175px" }}>
              <i className="material-icons" style={{ color: "white" }}>
                stop
              </i>
              <span style={{ marginLeft: "4px", fontSize: "16px" }}>Stop</span>
            </button>
          </div>
        </div>
      )}

      {recordStatus === "recorded" && (
        <div className="col-12">
          <Button
            onClick={() => uploadVideoBlob(finalVideoBlob)}
            color="primary"
            variant="contained">
            <i className="material-icons" style={{ color: "white" }}>
              file_upload
            </i>
            <span style={{ marginLeft: "4px", fontSize: "16px" }}>Upload</span>
          </Button>
          <div style={{ padding: "25px 0px 15px 0px" }}>
            or
            <Button
              onClick={() => reRecord()}
              style={{ textTransform: "none", marginBottom: "5px", marginLeft: "15px" }}
              variant="outlined"
              size="small">
              Re-record
            </Button>
          </div>
        </div>
      )}

      {recordStatus === "uploading" && (
        <div className="col-12">
          <span style={{ color: "grey", marginBottom: "2px" }}>
            Please wait. Uploading...
          </span>
          {uploadedPercent === 0 ? (
            <LinearProgress />
          ) : (
            <LinearProgress variant="determinate" value={uploadedPercent} />
          )}
        </div>
      )}
    </div>
  );
}
