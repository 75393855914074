import React, { useState } from "react";
import LoadingButton from "../../util/buttons/loadingbutton";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { deleteUsers } from "services/api.service";

function DeleteUsersDialog(props) {
  const { fullScreen, selectedItems = [], dialogOpen, users = {} } = props;
  const [state, setState] = useState({ buttonState: "regular" });

  const submitDeleteUser = async () => {
    try {
      if (selectedItems.length === 0) {
        throw new Error("No users selected");
      }

      const tempUsers = { ...users };
      setState({ buttonState: "loading" });

      const userIdsToDelete = [];

      selectedItems.forEach((userId) => {
        if (userId === props.userUID) {
          throw new Error("You can't delete yourself as a user.");
        }
        userIdsToDelete.push(userId);
      });

      await deleteUsers(userIdsToDelete);

      userIdsToDelete.forEach((userId) => {
        delete tempUsers[userId];
      });

      props.setSelectedItems([]);
      props.setUsers(tempUsers);

      setState({ buttonState: "saved" });
    } catch (err) {
      const errorMessage = err.data?.message || err.message;
      console.error(errorMessage);
      setState({ errorMessage: errorMessage });
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={dialogOpen}
      onClose={props.closeDialog}
      aria-labelledby="delete user dialog">
      <div style={{ minWidth: "250px", padding: "20px" }}>
        <div
          className="row col-12 pagePadding"
          style={{ padding: "20px", minWidth: "250px" }}>
          <div className="col-10">
            <h2>Delete Users</h2>
            <p className="greyDesc" style={{ fontSize: "14px" }}>
              Permanantly delete {selectedItems.length} users. You cannot undo this
              action.
            </p>
          </div>

          <div className="col-2 justify-content-end">
            <IconButton aria-label="close" onClick={props.closeDialog}>
              <i className="material-icons">close</i>
            </IconButton>
          </div>

          {state.errorMessage && (
            <div className="errorMessageBox">
              <b>{state.errorMessage}</b>
            </div>
          )}

          <div className="col-12 justify-content-center" style={{ padding: "20px 0px" }}>
            <LoadingButton
              variant="contained"
              color="primary"
              buttonState={state.buttonState}
              savedLabel={"Deleted"}
              label={"Delete User"}
              clickedRegularButton={() => submitDeleteUser()}
              clickedSavedButton={props.closeDialog}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default withMobileDialog()(DeleteUsersDialog);
