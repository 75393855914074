import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import dotenv from "dotenv";

// prioritize .env.local, then .env.environment if they exist
dotenv.config({ path: "./.env.local" });
dotenv.config({ path: "./.env." + process.env.NODE_ENV });
dotenv.config();

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
