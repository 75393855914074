import React from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";

export default function UploadButtons(props) {
  let { status, percentCompleted } = props;
  let mediaRecorderAvailable = window.MediaRecorder ? true : false;
  let isIOS =
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(window.navigator.platform) ||
    (window.navigator.userAgent.includes("Mac") && "ontouchend" in document);
  if (isIOS) mediaRecorderAvailable = false;

  //then decide which buttons to show
  if (status === "requested" || status === "create") {
    const inputStyle = {
      uploadInput: {
        cursor: "pointer",
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        width: "100%",
        opacity: 0,
      },
    };
    let buttonDivClass = mediaRecorderAvailable ? "col-xs-12 col-md-5" : "col-12";

    return (
      <div className="col-12 row ">
        {mediaRecorderAvailable && (
          <>
            <div
              className={buttonDivClass + " justify-content-center"}
              style={{ padding: "10px" }}>
              <button className="trappbtn btn-green" onClick={props.createRecording}>
                <i className="material-icons" style={{ color: "white" }}>
                  videocam
                </i>
                <span style={{ marginLeft: "4px", fontSize: "16px" }}>
                  Create Recording
                </span>
              </button>
            </div>
            <div
              className="col-xs-12 col-md-2  justify-content-center align-items-center"
              style={{ padding: "10px" }}>
              <b>or</b>
            </div>
          </>
        )}
        <div
          className={buttonDivClass + " justify-content-center"}
          style={{ padding: "10px" }}>
          <Button variant="contained" color="default">
            <i className="material-icons">file_upload</i>
            <span>{props.mediaDevicesAvailable ? "Upload File" : "Create/Upload"}</span>
            <input
              type="file"
              accept="video/mp4,video/x-m4v,video/*"
              id="mediaPlayer-1"
              style={inputStyle.uploadInput}
              onChange={props.uploadFile}
            />
          </Button>
        </div>
      </div>
    );
  } else if (status === "createError") {
    const inputStyle = {
      uploadInput: {
        cursor: "pointer",
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        width: "100%",
        opacity: 0,
      },
    };

    return (
      <div className="col-12 row justify-content-center">
        <div
          className="col-xs-12 col-md-5 justify-content-center"
          style={{ padding: "10px" }}>
          <Button variant="contained" color="primary">
            <i className="material-icons">file_upload</i>
            <span>Upload</span>
            <input
              type="file"
              accept="video/mp4,video/x-m4v,video/*"
              id="mediaPlayer-2"
              style={inputStyle.uploadInput}
            />
          </Button>
        </div>
      </div>
    );
  } else if (status === "record") {
    return (
      <div className="col-12  justify-content-center" style={{ paddingBottom: "10px" }}>
        <div className=" align-items-center" style={{ padding: "10px" }}>
          <button
            className="notRecordingButton"
            onClick={props.startRecordingCountdown}
          />
        </div>
        <button className="trappbtn btn-green" onClick={props.startRecordingCountdown}>
          <i className="material-icons" style={{ color: "white" }}>
            videocam
          </i>
          <span style={{ marginLeft: "4px", fontSize: "16px" }}>Start Record</span>
        </button>
      </div>
    );
  } else if (status === "recordingCountdown") {
    return null;
  } else if (status === "recording") {
    return (
      <div
        className="col-12  align-items-center justify-content-center"
        style={{ paddingBottom: "10px" }}>
        <div className=" align-items-center" style={{ padding: "10px" }}>
          <button className="recordingButton" onClick={props.stopRecording} />
        </div>

        <button className="trappbtn btn-red" onClick={props.stopRecording}>
          <i className="material-icons" style={{ color: "white" }}>
            stop
          </i>
          <span style={{ marginLeft: "4px", fontSize: "16px" }}>Stop</span>
        </button>
      </div>
    );
  } else if (status === "recorded") {
    return (
      <div className="col-12 row ">
        <div className="col-12  justify-content-center" style={{ padding: "10px" }}>
          <button onClick={props.uploadRecording} className="trappbtn btn-green">
            <i className="material-icons" style={{ color: "white" }}>
              file_upload
            </i>
            <span style={{ marginLeft: "4px", fontSize: "16px" }}>
              Upload/Save Recording
            </span>
          </button>
        </div>
        <div
          className="col-12  justify-content-center align-items-center"
          style={{ padding: "10px" }}>
          <b>or</b>
        </div>
        <div className="col-12  justify-content-center" style={{ padding: "10px" }}>
          <Button onClick={props.createRecording} variant="outlined" color="default">
            Start Over
          </Button>
        </div>
      </div>
    );
  } else if (status === "uploading" || status === "uploadedGoodToExit") {
    return (
      <>
        <div className="col-12" style={{ padding: "10px" }}>
          <p
            className="greyDesc"
            style={{ fontSize: "16px", fontWeight: "500", textAlign: "center" }}>
            Uploading. Please don&apos;t close this page yet.
          </p>
        </div>
        <div className="col-12" style={{ padding: "20px" }}>
          <LinearProgress variant="determinate" value={percentCompleted} />
        </div>
        <div className="col-12  justify-content-center align-items-center">
          <Button color="primary" variant="contained">
            <CircularProgress size={30} color="inherit" />
          </Button>
        </div>
      </>
    );
  } else if (status === "webMUploaded") {
    return (
      <div className="col-12 row ">
        <div className="notificationMessageBox" style={{ margin: "10px" }}>
          <b>Video uploaded and processing.</b>
        </div>

        <div className="col-12" style={{ marginTop: "10px" }}>
          <LinearProgress />
        </div>
        <div
          className="col-12  justify-content-center align-items-center"
          style={{ padding: "10px 0px" }}>
          <Button color="default" variant="text" onClick={props.deleteMediaUpload}>
            <i className="material-icons" style={{ color: "black" }}>
              delete_forever
            </i>
            Remove
          </Button>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="col-12  justify-content-center align-items-center"
        style={{ paddingBottom: "10px" }}>
        <Button color="default" variant="text" onClick={props.deleteMediaUpload}>
          <i className="material-icons" style={{ color: "black" }}>
            delete_forever
          </i>
          Remove
        </Button>
      </div>
    );
  }
}
