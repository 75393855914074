import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useParams, Route, Switch, Link } from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";
import Videos from "./videos/videos";
import VideosBulkRequest from "./videos/bulkRequest/bulkRequest";
import Account from "./account";
import AccountAnalytics from "./analytics/accountAnalytics";
import Birthday from "./birthday/birthday";
import Task from "./task/task";
import Subscription from "./subscription/subscription";
import Settings from "./settings/settings";
import AccountSettings from "./settings/account/accountSettings";
import Contacts from "./contacts/contacts";
import Unsubscribes from "./contacts/unsubscribes/unsubscribes";
import ListIntegrations from "./integrations/listIntegrations";
import Integration from "./integrations/integration";
import ManageNote from "./notes/editNote/manageNote";
import ContactProfile from "./contacts/profile/contactProfile";
import EditVideo from "./videos/editVideo/editVideo";
import Users from "./users/users";
import Notes from "./notes/notes";
import Admin from "./admin/pages/admin.js";
import AdminAccount from "./admin/pages/admin-account.js";
import Roles from "./admin/pages/roles.js";
import SuperAdmins from "./admin/pages/superAdmins";
import Button from "@material-ui/core/Button";
import PageNotFound444 from "components/util/pageNotFound444";
import styles from "./account-manager.module.css";
import {
  getAccount,
  getIdToken,
  getSelf,
  onAuthStateChange,
  collectUsageEvent,
  setAccountId,
} from "services/api.service";

const publicPaths = [
  "/openid/redirect",
  "/loginfrominvite",
  "/resetpassword",
  "/terms",
  "/userContentTerms",
  "/privacy",
  "/gdpr",
  "/security",
];

export default function AccountManager(props) {
  const [initialized, setInitialized] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    userInfo: "loading",
    ssoProvider: null,
  });

  let passedSetStateFromApp = (state, value, stateTwo, valueTwo) => {
    setState((prevState) => {
      // Object.assign would also work
      return { ...prevState, [state]: value, [stateTwo]: valueTwo };
    });
  };
  const params = useParams();
  const routeAccountId = params.accountId;
  if (state.accountInfo?.id && !routeAccountId) {
    props.history.push(`/${state.accountInfo.id}${props.match.url}`);
  }

  // Handle user state changes
  const initializeUser = useCallback(handleInit, []);

  async function handleInit(authState) {
    try {
      if (authState) {
        const userInfo = await getSelf();
        let userRole =
          routeAccountId === "auth"
            ? userInfo.roles[0]
            : userInfo.roles.find((role) => role.accountId === routeAccountId);

        const domains = new Set(["gratavid.com", "gravyty.com", "graduway.com"]);
        const userEmailDomain = userInfo.email.split("@")[1];
        const isInternalUser = domains.has(userEmailDomain);

        let accountInfo;

        if (!userRole && isInternalUser) {
          accountInfo = await getAccount(routeAccountId);
          if (accountInfo) {
            userRole = {
              isAdmin: true,
              isBulkSender: true,
              isNoteEditor: true,
              accountId: accountInfo.id,
              account: {
                name: accountInfo.name,
              },
              notifications: userInfo.roles[0].notifications,
            };
            userInfo.roles.push(userRole);
          }
        }

        if (!userRole) {
          setHasError(true);
          setState({ userInfo: null, accountInfo: {} });
          return;
        }

        if (routeAccountId === "auth") {
          props.history.push(`/a/${userRole.accountId}`);
        }

        setAccountId(userRole.accountId);

        //get account and user data
        accountInfo = accountInfo ?? (await getAccount().catch(() => {}));

        if (!accountInfo) {
          setHasError(true);
          return;
        }

        // TODO: temporary firebase support during migration
        accountInfo.accountUID = accountInfo.firebaseId;

        let bulkNoteSendingDisabled = false,
          bulkVideosDisabled = false,
          bulkTasksDisabled = false,
          bulkUploadContactsDisabled = false,
          reccurringCampaignsDisabled = false;

        if (!userRole.isBulkSender) bulkNoteSendingDisabled = true;

        setState({
          bulkNoteSendingDisabled,
          bulkVideosDisabled,
          reccurringCampaignsDisabled,
          bulkUploadContactsDisabled,
          bulkTasksDisabled,
          userNoteWriteNoteDisabled: !userRole.isNoteEditor,
          accountInfo,
          userInfo,
          userRole,
          ssoProvider: authState.sso,
          userId: userInfo.id,
          userUID: userInfo.firebaseId,
          email: userInfo.email,
          userIsAdmin: userRole.isAdmin,
          isSuperAdmin: userInfo.isSuperAdmin,
          isInternalUser,
        });

        window.pendo = window.pendo || {};

        if (!initialized) {
          if (typeof window.pendo.initialize === "function") {
            window.pendo.initialize({
              visitor: {
                id: userInfo?.email,
              },
              account: {
                id: accountInfo.name,
              },
            });
          } else {
            console.error("Pendo is not properly initialized");
          }
          //send login event to totango. no need to wait on it. responseType is arraybuffer since we return blank image
          collectUsageEvent({
            event: "userloggedIn",
            accountId: accountInfo.id,
            userId: userInfo.id,
          });

          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "gv.userLoggedIn",
            userEmail: userInfo.email,
          });

          setInitialized(true);
        }
      } else {
        setState({ userInfo: null, accountInfo: {} });
        props.history.push(`/a/auth`);
      }
    } catch (err) {
      console.error(err.message);
    }
  }
  //get user data. reload if appState changes from background to foreground
  useEffect(() => {
    onAuthStateChange(initializeUser);

    if (!publicPaths.includes(window.location.pathname)) {
      getIdToken()
        .then(initializeUser)
        .catch(() => initializeUser());
    } else {
      initializeUser();
    }
  }, [initializeUser]);

  const changeAccount = async (accountId, accountName) => {
    setIsLoading(true);
    const userInfo = state.userInfo;
    let userRole = userInfo.roles.find((role) => role.accountId === accountId);

    if (!userRole && state.isInternalUser) {
      userRole = {
        isAdmin: true,
        isBulkSender: true,
        isNoteEditor: true,
        accountId,
        account: {
          name: accountName,
        },
        notifications: userInfo.roles[0].notifications,
      };
      userInfo.roles.push(userRole);
    }
    setAccountId(accountId);
    const accountInfo = await getAccount();
    setState({
      ...state,
      userInfo,
      accountInfo,
      userRole,
      bulkNoteSendingDisabled: !userRole.isBulkSender,
      userNoteWriteNoteDisabled: !userRole.isNoteEditor,
      userIsAdmin: userRole.isAdmin,
    });

    props.history.push(`/a/${accountId}/`);
    setIsLoading(false);
  };

  return state.userInfo === "loading" || isLoading ? (
    <div>
      <div style={{ padding: "20px" }}>
        <LinearProgress />
      </div>
    </div>
  ) : hasError ? (
    <div className="errorMessageBox">
      Unable to load account. Please contact the account admin to add you to this account.
      <Button
        className={styles.accountButton}
        variant="contained"
        color="primary"
        component={Link}
        to={"/"}>
        Return to my account
      </Button>
    </div>
  ) : (
    <Switch>
      <Route
        exact
        path={"/a/:accountId/"}
        render={(props) => (
          <Account
            componentPath={Task}
            pageId="task"
            {...props}
            {...state}
            passedSetStateFromApp={passedSetStateFromApp}
            changeAccount={changeAccount}
          />
        )}
      />
      <Route
        exact
        path={"/a/:accountId/analytics"}
        render={(props) => (
          <Account
            componentPath={AccountAnalytics}
            {...props}
            {...state}
            passedSetStateFromApp={passedSetStateFromApp}
            changeAccount={changeAccount}
          />
        )}
      />
      <Route
        exact
        path={"/a/:accountId/birthday"}
        render={(props) => (
          <Account
            componentPath={Birthday}
            pageId="birthday"
            {...props}
            {...state}
            passedSetStateFromApp={passedSetStateFromApp}
            changeAccount={changeAccount}
          />
        )}
      />
      <Route
        exact
        path={"/a/:accountId/subscription"}
        render={(props) => (
          <Account
            componentPath={Subscription}
            {...props}
            hideInactiveWarning={true}
            {...state}
            passedSetStateFromApp={passedSetStateFromApp}
            changeAccount={changeAccount}
          />
        )}
      />
      <Route
        exact
        path={"/a/:accountId/task"}
        render={(props) => (
          <Account
            componentPath={Task}
            pageId="task"
            {...props}
            {...state}
            passedSetStateFromApp={passedSetStateFromApp}
            changeAccount={changeAccount}
          />
        )}
      />
      <Route
        exact
        path={"/a/:accountId/createTaskFromHyperlink"}
        render={(props) => (
          <Account
            componentPath={Task}
            pageId="task"
            {...props}
            {...state}
            passedSetStateFromApp={passedSetStateFromApp}
            changeAccount={changeAccount}
          />
        )}
      />
      <Route
        exact
        path={"/a/:accountId/branding"}
        render={(props) => (
          <Account
            componentPath={AccountSettings}
            {...props}
            {...state}
            passedSetStateFromApp={passedSetStateFromApp}
            changeAccount={changeAccount}
          />
        )}
      />
      <Route
        exact
        path={"/a/:accountId/accountsettings"}
        render={(props) => (
          <Account
            componentPath={AccountSettings}
            {...props}
            {...state}
            passedSetStateFromApp={passedSetStateFromApp}
            changeAccount={changeAccount}
          />
        )}
      />
      <Route
        exact
        path={"/a/:accountId/settings"}
        render={(props) => (
          <Account
            componentPath={Settings}
            {...props}
            {...state}
            passedSetStateFromApp={passedSetStateFromApp}
            changeAccount={changeAccount}
          />
        )}
      />
      <Route
        exact
        path={"/a/:accountId/contacts"}
        render={(props) => (
          <Account
            componentPath={Contacts}
            pageId="contacts"
            {...props}
            {...state}
            passedSetStateFromApp={passedSetStateFromApp}
            changeAccount={changeAccount}
          />
        )}
      />
      <Route
        exact
        path={"/a/:accountId/unsubscribes"}
        render={(props) => (
          <Account
            componentPath={Unsubscribes}
            {...props}
            {...state}
            passedSetStateFromApp={passedSetStateFromApp}
            changeAccount={changeAccount}
          />
        )}
      />
      <Route
        exact
        path={"/a/:accountId/integrations"}
        render={(props) => (
          <Account
            componentPath={ListIntegrations}
            pageId="integrations"
            {...props}
            {...state}
            passedSetStateFromApp={passedSetStateFromApp}
            changeAccount={changeAccount}
          />
        )}
      />
      <Route
        exact
        path={"/a/:accountId/integrations/:integrationId"}
        render={(props) => (
          <Account
            componentPath={Integration}
            integrationId={props.match.params.integrationId}
            pageId={"integration-" + props.match.params.integrationId}
            componentKey={props.match.params.integrationId}
            {...props}
            {...state}
            passedSetStateFromApp={passedSetStateFromApp}
            changeAccount={changeAccount}
          />
        )}
      />
      <Route
        exact
        path={"/a/:accountId/notes"}
        render={(props) => (
          <Account
            componentPath={Notes}
            pageId="notes"
            {...props}
            {...state}
            passedSetStateFromApp={passedSetStateFromApp}
            changeAccount={changeAccount}
          />
        )}
      />
      <Route
        exact
        path={"/a/:accountId/videos"}
        render={(props) => (
          <Account
            componentPath={Videos}
            pageId="videos"
            {...props}
            {...state}
            passedSetStateFromApp={passedSetStateFromApp}
            changeAccount={changeAccount}
          />
        )}
      />
      <Route
        exact
        path={"/a/:accountId/videos/bulkupload"}
        render={() => (
          <Account
            componentPath={VideosBulkRequest}
            pageId="bulk-video"
            passedSetStateFromApp={passedSetStateFromApp}
            changeAccount={changeAccount}
            {...state}
            bulkVideosDisabled={state.bulkVideosDisabled}
            accountInfo={state.accountInfo}
            userIsAdmin={state.userIsAdmin}
          />
        )}
      />
      <Route
        exact
        path={"/a/:accountId/users"}
        render={(props) => (
          <Account
            componentPath={Users}
            pageId="users"
            {...props}
            {...state}
            passedSetStateFromApp={passedSetStateFromApp}
            changeAccount={changeAccount}
          />
        )}
      />
      <Route
        exact
        path={"/a/:accountId/videos/:videoId"}
        render={(props) => (
          <Account
            componentPath={EditVideo}
            pageId="editvideo"
            {...state}
            {...props}
            videoId={props.match.params.videoId}
            recorderName={state?.userInfo?.name ?? ""}
            accountId={state.accountInfo.id}
            componentKey={props.match.params.videoId}
            passedSetStateFromApp={passedSetStateFromApp}
            changeAccount={changeAccount}
          />
        )}
      />
      <Route
        exact
        path={"/a/:accountId/notes/:noteId"}
        render={(props) => (
          <Account
            componentPath={ManageNote}
            pageId="editnote"
            noteId={props.match.params.noteId}
            componentKey={props.match.params.noteId}
            {...props}
            {...state}
            passedSetStateFromApp={passedSetStateFromApp}
            changeAccount={changeAccount}
          />
        )}
      />
      <Route
        exact
        path={"/a/:accountId/contacts/:contactId"}
        render={(props) => (
          <Account
            componentPath={ContactProfile}
            contactId={props.match.params.contactId}
            componentKey={props.match.params.contactId}
            {...props}
            {...state}
            passedSetStateFromApp={passedSetStateFromApp}
            changeAccount={changeAccount}
          />
        )}
      />
      <Route
        exact
        path={"/a/:accountId/admin/accounts"}
        render={(props) => (
          <Account
            componentPath={Admin}
            pageId="admin"
            {...props}
            {...state}
            passedSetStateFromApp={passedSetStateFromApp}
            changeAccount={changeAccount}
          />
        )}
      />
      <Route
        exact
        path={"/a/:accountId/admin/accounts/new"}
        render={(props) => (
          <Account
            componentPath={AdminAccount}
            pageId="deploy"
            {...props}
            {...state}
            passedSetStateFromApp={passedSetStateFromApp}
            changeAccount={changeAccount}
          />
        )}
      />
      <Route
        exact
        path={"/a/:accountId/admin/accounts/:accountUID"}
        render={(props) => (
          <Account
            componentPath={AdminAccount}
            pageId="edit-account"
            {...props}
            {...state}
            accountUID={props.match.params.accountUID}
            componentKey={props.match.params.accountUID}
            passedSetStateFromApp={passedSetStateFromApp}
            changeAccount={changeAccount}
          />
        )}
      />
      <Route
        exact
        path={"/a/:accountId/admin/accounts/:accountUID/users"}
        render={(props) => (
          <Account
            componentPath={Roles}
            pageId="roles"
            {...props}
            {...state}
            passedSetStateFromApp={passedSetStateFromApp}
            changeAccount={changeAccount}
          />
        )}
      />
      <Route
        exact
        path={"/a/:accountId/admin/super-admins"}
        render={(props) => (
          <Account
            componentPath={SuperAdmins}
            pageId="superAdmins"
            {...props}
            {...state}
            passedSetStateFromApp={passedSetStateFromApp}
            changeAccount={changeAccount}
          />
        )}
      />
      <Route
        path="*"
        render={() => <PageNotFound444 errorMessage="This page does not exist" static />}
      />
    </Switch>
  );
}

AccountManager.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
