import React, { useState } from "react";
import PropTypes from "prop-types";
import { Tabs, Tab } from "@material-ui/core";
import VideoPreview from "./videoPreview";
import Trimmer from "./trim/trimVideo";
import Captions from "./captions/captions";
import BigBreadcrumb from "../../../util/breadcrumb/bigBreadcrumb";
import { withStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import UploadNeeded from "./upload/uploadNeeded";
import Breadcrumb from "../../../util/breadcrumb/breadcrumb";
import { getSearchParams } from "util/helpers";

const AntTabs = withStyles({
  indicator: {
    backgroundColor: "#f76c6c",
  },
})(Tabs);

const AntTab = withStyles(() => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    marginRight: "10px",
  },
  selected: {},
}))((props) => <Tab {...props} />);

export default function ManageVideo(props) {
  const location = useLocation();
  const history = useHistory();
  const urlParams = getSearchParams();
  const [tabSelected, setTabSelected] = useState(urlParams.get("tab") || "video");

  const onTabSelect = (tabValue) => {
    urlParams.set("tab", tabValue);
    history.replace({ pathname: location.pathname, search: urlParams.toString() });
    setTabSelected(tabValue);
  };

  function a11yProps(index) {
    return {
      id: `task-tab-${index}`,
      "aria-controls": `task-tabpanel-${index}`,
    };
  }

  const tabTextStyle = {
    textTransform: "none",
    color: "rgb(71, 98, 130)",
    fontSize: "14px",
    fontWeight: 600,
  };

  if (props.status === "mediaURLUploaded") {
    return (
      <div className="row col-12">
        <BigBreadcrumb
          indexName="videos"
          accountId={props.accountId}
          id={props.videoId}
          title={props.title ?? ""}
          setTitle={(value) => props.passedSetEditVideoState("title", value)}
          history={history}
        />

        <div
          className="col-12"
          style={{ borderBottom: "1px #ddd solid", padding: "0px 8.5px" }}>
          <AntTabs
            value={tabSelected}
            onChange={(e, newValue) => onTabSelect(newValue)}
            aria-label="edit video tabs">
            <AntTab label="Video" {...a11yProps(0)} value="video" style={tabTextStyle} />
            <AntTab label="Editor" {...a11yProps(1)} value="edit" style={tabTextStyle} />
            <AntTab
              label="Captions"
              {...a11yProps(2)}
              value="captions"
              style={tabTextStyle}
            />
          </AntTabs>
        </div>

        {tabSelected === "video" && (
          <VideoPreview
            accountId={props.accountId}
            videoId={props.videoId}
            uploadType={props.uploadType}
            gif={props.gif}
            speakerName={props.speakerName}
            thumbnail={props.thumbnail}
            mediaURL={props.mediaURL}
            status={props.status}
            audioSrc={props.audioSrc}
            videoUploadStatusChanged={props.videoUploadStatusChanged}
            passedSetEditVideoState={props.passedSetEditVideoState}
          />
        )}
        {tabSelected === "edit" && (
          <Trimmer
            videoId={props.videoId}
            mediaURL={props.mediaURL}
            status={props.status}
            videoUploadStatusChanged={props.videoUploadStatusChanged}
            updateStatus={props.updateStatus}
            accountId={props.accountId}
          />
        )}
        {tabSelected === "captions" && (
          <Captions
            accountId={props.accountId}
            videoId={props.videoId}
            audioSrc={props.audioSrc}
            duration={props.duration}
            mediaURL={props.mediaURL}
            status={props.status}
            captions={props.captions}
            passedSetEditVideoState={props.passedSetEditVideoState}
          />
        )}
      </div>
    );
  } else {
    return (
      <div className="row col-12 pagePadding">
        <Breadcrumb
          accountId={props.accountId}
          indexName="videos"
          id={props.videoId}
          title={props.title}
          titleKey="title"
          passedSetState={props.passedSetEditVideoState}
          history={history}
        />
        <UploadNeeded
          {...props}
          accountId={props.accountId}
          recorderName={props.recorderName}
          videoId={props.videoId}
          status={props.status}
          speakerName={props.speakerName}
          videoRef={props.videoRef}
          setVideoRef={props.setVideoRef}
          videoUploadStatusChanged={props.videoUploadStatusChanged}
          updateStatus={props.updateStatus}
          deletedVideoState={props.deletedVideoState}
        />
      </div>
    );
  }
}

ManageVideo.propTypes = {
  accountId: PropTypes.string.isRequired,
  recorderName: PropTypes.string.isRequired,
  videoId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  videoRef: PropTypes.object,
  uploadType: PropTypes.string.isRequired,
  gif: PropTypes.string,
  thumbnail: PropTypes.string,
  mediaURL: PropTypes.string,
  audioSrc: PropTypes.string,
  status: PropTypes.string.isRequired,
  videoUploadStatusChanged: PropTypes.func.isRequired,
  updateStatus: PropTypes.func.isRequired,
  passedSetEditVideoState: PropTypes.func.isRequired,
  setVideoRef: PropTypes.func.isRequired,
  speakerName: PropTypes.string,
  deletedVideoState: PropTypes.object,
};
