import React, { useState } from "react";
import PropTypes from "prop-types";
import Authenticate from "./authenticate";
import DeAuthenticateDialog from "./deAuthenticateDialog";
import QuestionToLink from "../../../../util/explainers/questionToLink";
import DonationCreated from "./actions/donationCreated";

export default function Neon(props) {
  let { integrationDetails, data = {} } = props;
  let { events = {} } = data;
  let [connectionInfo, setConnectionInfo] = useState(props.data?.connectionInfo);
  let { header, logoSrc, brandHex } = integrationDetails;

  if (!connectionInfo)
    return (
      <Authenticate
        connectionInfo={connectionInfo}
        setConnectionInfo={setConnectionInfo}
        {...props}
      />
    );

  return (
    <div className="col-12 row">
      <div
        style={{
          width: "70px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <div
          style={{
            marginRight: "8px",
            width: "45px",
            height: "45px",
            backgroundColor: brandHex,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <img src={logoSrc} width="50px" alt="logo" />
        </div>
      </div>
      <div style={{ flex: 1 }}>
        <div className="row col-12 align-items-center">
          <h1>{header}</h1>
          <QuestionToLink id="neonIntegration" desc="Neon One Integration" />
        </div>

        <span style={{ display: "flex", alignItems: "center" }}>
          <i
            className="material-icons"
            title="check icon"
            style={{ color: "green", fontSize: "25px", marginRight: "4px" }}>
            check_circle
          </i>
          Connected.
          <DeAuthenticateDialog {...props} />
        </span>
      </div>

      <DonationCreated {...props} eventData={events?.donationCreated} />

      <div className="col-12" style={{ height: "100px" }} />
    </div>
  );
}

Neon.propTypes = {
  data: PropTypes.shape({ active: PropTypes.bool, connectionInfo: PropTypes.object }),
  integrationDetails: PropTypes.shape({
    header: PropTypes.string,
    logoSrc: PropTypes.string,
    brandHex: PropTypes.string,
  }),
};
