import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { PropTypes } from "prop-types";
import ListImagesDialog from "../../../../util/images/listImagesDialog";
import SelectColor from "../../../../util/selectColor";
import TextInputToApi from "../../../../util/hooks/input/textInputToApi";
import { patchAccount } from "../../../../../services/api.service.js";
import { validURL } from "../../../../../util/urls";

export default function Branding(props) {
  let [state, setState] = useState({});
  let [buttonLinkErrorMessage, setButtonLinkErrorMessage] = useState(null);
  let [dialogOpen, setDialogOpen] = useState(false);
  let [logo, setLogo] = useState(
    props.accountInfo.logo || "https://gratavid.com/static/img/icon.png"
  );
  let [primaryColor, setPrimaryColor] = useState(
    props.accountInfo.primaryColor || "#24305e"
  );
  let [secondaryColor, setSecondaryColor] = useState(
    props.accountInfo.secondaryColor || "#FFFFFF"
  );

  const validateWebsite = (value) => {
    if (!value.includes("http") && !value.includes("mailto:")) {
      setButtonLinkErrorMessage(
        "Link must include http://, https://, or mailto: at the beginning. ie - https://www.gratavid.com/"
      );
    } else if (validURL(value) || value.startsWith("mailto:")) {
      setButtonLinkErrorMessage(null);
    } else {
      setButtonLinkErrorMessage("Invalid URL");
    }
  };

  const handleLogoAndColor = (field, value) => {
    if (field === "logo") {
      setLogo(value);
    } else if (field === "primaryColor") {
      setPrimaryColor(value);
    } else if (field === "secondaryColor") {
      setSecondaryColor(value);
    }
  };

  const onChange = async (field, value) => {
    if (field === "website") {
      validateWebsite(value);
    }

    try {
      await patchAccount({ [field]: value });

      handleLogoAndColor(field, value);
    } catch (err) {
      console.error(err.message);
      setState({ errorMessage: err.message });
    }
  };

  return (
    <div className="row col-12 align-items-start" style={{ marginTop: "30px" }}>
      {state.errorMessage && (
        <div className="col-12 errorMessageBox">
          <b>{state.errorMessage}</b>
        </div>
      )}

      <div className="col-12 col-md-6" style={{ padding: "10px 5px" }}>
        <TextInputToApi
          initialValue={props.accountInfo.name}
          label="Organization Name"
          variant="outlined"
          field="name"
          apiCall={onChange}
        />
      </div>

      <div className="col-12 col-md-6" style={{ padding: "10px 5px" }}>
        <TextInputToApi
          initialValue={props.accountInfo.website}
          label="Website"
          variant="outlined"
          parentErrorMessage={buttonLinkErrorMessage}
          field="website"
          apiCall={onChange}
        />
      </div>

      <div className="col-12 col-md-4" style={{ padding: "10px 5px" }}>
        <TextInputToApi
          initialValue={props.accountInfo.address1}
          label="Address Line 1"
          variant="outlined"
          field="address1"
          apiCall={onChange}
        />
      </div>

      <div className="col-12 col-md-4" style={{ padding: "10px 5px" }}>
        <TextInputToApi
          initialValue={props.accountInfo.address2}
          label="Address Line 2"
          variant="outlined"
          field="address2"
          apiCall={onChange}
        />
      </div>

      <div className="col-12 col-md-4" style={{ padding: "10px 5px" }}>
        <TextInputToApi
          initialValue={props.accountInfo.address3}
          label="Address Line 3 (optional)"
          variant="outlined"
          field="address3"
          apiCall={onChange}
        />
      </div>

      <div
        className="col-12 col-md-6 justify-content-center row"
        style={{ padding: "10px 5px" }}>
        <div style={{ marginRight: "20px" }}>
          <label
            htmlFor="selectLogo"
            style={{ fontSize: "12px", color: "rgba(0,0,0,0.54)", marginTop: "10px" }}>
            Logo
          </label>
          <br />
          <Button
            variant="outlined"
            style={{ width: "134px", position: "relative", borderRadius: "16px" }}
            onClick={() => setDialogOpen(true)}
            aria-label="select logo"
            id="selectLogo">
            <img
              src={logo}
              width="100%"
              style={{ minWidth: "100px", minHeight: "100px" }}
              alt="logo"
              aria-label="logo"
            />
          </Button>
        </div>
      </div>

      <div className="col-12 col-md-6 row">
        <div className="col-12" style={{ padding: "10px 0px" }}>
          <label htmlFor="website" className="textField-label">
            Select Primary Color
          </label>
          <SelectColor
            color={primaryColor}
            colorKey="primaryColor"
            passedSetState={onChange}
          />
        </div>

        <div className="col-12" style={{ padding: "10px 0px" }}>
          <label htmlFor="website" className="textField-label">
            Select Secondary Color (optional)
          </label>
          <SelectColor
            color={secondaryColor}
            colorKey="secondaryColor"
            passedSetState={onChange}
          />
        </div>
      </div>

      <ListImagesDialog
        accountInfo={props.accountInfo}
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        passedSetState={(field, url) => onChange("logo", url)}
        logo={logo}
        type="logo"
      />
    </div>
  );
}

Branding.propTypes = {
  accountInfo: PropTypes.shape({
    logo: PropTypes.string.isRequired,
    firebaseId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    primaryColor: PropTypes.string.isRequired,
    secondaryColor: PropTypes.string.isRequired,
    address1: PropTypes.string.isRequired,
    address2: PropTypes.string.isRequired,
    address3: PropTypes.string.isRequired,
    website: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
};
