import React, { useEffect, useState, useReducer } from "react";
import axios from "axios";
import { createVideos, createVideoSignedUrl, editVideo } from "services/api.service";
//const initialiseCamera = async() => await navigator.mediaDevices.getUserMedia({audio: true, video: true});

export const useCamera = (videoRef) => {
  const [video, setVideo] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  let [selectedVideoDeviceId, setSelectedVideoDeviceId] = useState(null);
  let [videoDevices, setVideoDevices] = useState([]);

  useEffect(() => {
    if (video || !videoRef.current) return;

    const videoElement = videoRef.current;
    if (videoElement instanceof HTMLVideoElement) setVideo(videoRef.current);
  }, [videoRef, video]);

  useEffect(() => {
    if (!video) return;

    let initializeCamera = async () => {
      try {
        //make sure to stop any existing streams first
        let currentStream = video.srcObject;
        if (currentStream) currentStream.getTracks().forEach((track) => track.stop());

        //get camera devices
        //if newVideoDeviceId exist, then createRecording was called after used changed video device on videoPlayer
        let tempDevices = [];
        //get devices so we can show them to user before the record. they might use 2nd or external camera
        let devices = await window.navigator.mediaDevices.enumerateDevices();
        devices.forEach((device) => {
          if (device.kind === "videoinput") tempDevices.push(device);
        });

        let deviceId;
        let preferredCameraDeviceId = window.localStorage.getItem(
          "preferredCameraDeviceId"
        );
        if (selectedVideoDeviceId) deviceId = selectedVideoDeviceId;
        else if (preferredCameraDeviceId) deviceId = preferredCameraDeviceId;
        else deviceId = tempDevices && tempDevices[0] && tempDevices[0].deviceId; //else just make first device the one being used to record

        let contraints = {
          audio: true,
          video: {
            video: { width: 10240, height: 4320 },
            //facingMode: 'user', was causing error for some users
            deviceId: deviceId ? deviceId : undefined,
          },
        };

        let stream = await navigator.mediaDevices.getUserMedia(contraints);

        // Older browsers don't support srcObject
        if ("srcObject" in video) {
          video.srcObject = stream;
        } else {
          video.src = window.URL.createObjectURL(stream);
        }

        //added onloadedmetadata so doesn't start too early
        video.onloadedmetadata = () => {
          if (stream) {
            video.play();
          }
        };

        //get the videoDeviceId in use, this will keep device selector in sync
        setSelectedVideoDeviceId(deviceId);
        setVideoDevices(tempDevices);
      } catch (err) {
        console.error(err);
        console.error(err.message);
        let errorMessage =
          "Camera error. Make sure you are using Google Chrome to access this page, or access this page from your smartphone. " +
          err.message;
        if (err.message === "Permission denied")
          errorMessage = (
            <span>
              Camera Permission Denied. Please allow this website to access your camera.
              Visit{" "}
              <a
                href="https://graduwayhelp.zendesk.com/hc/en-us/articles/6655991934492-Camera-Permission-Denied-How-To-Fix"
                rel="noopener noreferrer"
                target="_blank">
                Camera Permission Denied - How To Fix
              </a>{" "}
              for help.
            </span>
          );
        setErrorMessage(errorMessage);
      }
    };

    initializeCamera();

    return () => {
      let stream = video.srcObject;
      if (stream) stream.getTracks().forEach((track) => track.stop());
    };
  }, [video, selectedVideoDeviceId]);

  return [
    video,
    errorMessage,
    videoDevices,
    selectedVideoDeviceId,
    setSelectedVideoDeviceId,
  ];
};

export const useRecording = (video, selectedVideoDeviceId, recorderName, accountId) => {
  //we have to use a reducer otherwise chunks will get lost
  //reducers help preserve the previos state
  function reducer(state, action) {
    switch (action.type) {
      case "ondataavailable":
        let newChunks = [...state.chunks, action.payload];
        return { ...state, chunks: newChunks };
      case "timer":
        return { ...state, recordingCountdown: state.recordingCountdown - 1 };
      case "reset":
        return { chunks: [], recordingCountdown: 3 };
      default:
        throw new Error();
    }
  }

  const [state, dispatch] = useReducer(reducer, { chunks: [], recordingCountdown: 3 });

  let [recordStatus, setRecordStatus] = useState("prerecord");
  let [mediaRecorder, setMediaRecorder] = useState([]);
  let [finalVideoBlob, setFinalVideoBlob] = useState(null);
  let [uploadedPercent, setUploadedPercent] = useState(0);
  let [errorMessage, setErrorMessage] = useState(null);

  let startRecordingCountdown = (e) => {
    if (e) e.preventDefault();

    try {
      startRecording();
      /*
              let timer = setInterval( ()=> {
                console.log("state in timer: " + state.recordingCountdown)
                if (state.recordingCountdown === 0){
                  clearInterval(timer);
                  startRecording()
                } else{
                  dispatch({type: 'timer', payload: -1})
                }
              }, 1000);
              */
    } catch (err) {
      console.error(err.message);
      setErrorMessage(
        "We are sorry. Recording is not supported in this browser. Please open this link in Chrome to record a video, upload an existing video file, or access this page from your smartphone. " +
          err.message
      );
    }
  };

  let startRecording = () => {
    try {
      let stream = video.srcObject;
      let mediaRecorder = new MediaRecorder(stream);
      mediaRecorder.start(1);

      mediaRecorder.ondataavailable = (e) => {
        if (e.data && e.data.size > 0) {
          dispatch({ type: "ondataavailable", payload: e.data });
        }
      };

      setRecordStatus("recording");
      setMediaRecorder(mediaRecorder);
    } catch (err) {
      console.error(err.message);
      setErrorMessage(
        "We are sorry. Recording is not supported in this browser. Please open this link in Chrome to record a video, upload an existing video file, or access this page from your smartphone." +
          err.message
      );
    }
  };

  let stopRecording = (e) => {
    try {
      e.preventDefault();
      // stop the recorder
      if (mediaRecorder) mediaRecorder.stop();
      let stream = video.srcObject;
      stream.getTracks().forEach((track) => track.stop());
      const blob = new Blob(state.chunks, { type: "audio/*,video/*" });

      //must make sure srcObject is null
      video.srcObject = null;
      video.src = URL.createObjectURL(blob);

      //this is what we will upload
      setRecordStatus("recorded");
      setFinalVideoBlob(blob);
    } catch (err) {
      console.error(err.message);
      setErrorMessage("Error recording. Please upload an existing file.");
    }
  };

  let reRecord = async () => {
    try {
      //must make sure srcObject is null
      video.srcObject = null;
      setFinalVideoBlob(null);

      //reset chunks and recordingCountdown
      dispatch({ type: "reset" });
      setRecordStatus("prerecord");

      let contraints = {
        audio: true,
        video: {
          video: { width: 10240, height: 4320 },
          //facingMode: 'user', was causing error for some users
          deviceId: selectedVideoDeviceId ? selectedVideoDeviceId : undefined,
        },
      };

      let stream = await window.navigator.mediaDevices.getUserMedia(contraints);

      video.srcObject = stream;
      video.play();
    } catch (err) {
      console.error(err.message);
      setErrorMessage("Error recording. Please upload an existing file.");
    }
  };

  let createVideoObject = async (title, createdByTask = false) => {
    try {
      setRecordStatus("uploading");

      const videoIds = await createVideos([
        {
          title: title,
          foldersFilter: [],
          uploadType: "upload",
          createdByTask: createdByTask ?? false,
        },
      ]);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: "gv.addVideo" });

      const video = videoIds[0];

      if (!video) {
        throw new Error("Error creating video");
      }

      return { videoId: video.id, title };
    } catch (err) {
      let errorMessage = err.data?.message || err.message;
      setErrorMessage(errorMessage);
    }
  };

  let mediaUploadProgress = (progressEvent, id) => {
    try {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setUploadedPercent(percentCompleted);
    } catch (err) {
      console.error(err.message);
    }
  };

  let uploadMedia = async (file, videoId) => {
    try {
      setRecordStatus("uploading");

      //max is 500 mb
      //a minute of record time is about 700mb
      let readUrl;

      let maxFileSize = 700 * 1024 * 1024;
      if (file && file.size > maxFileSize)
        throw new Error(
          "File is too large, must be smaller than 700mb. Recordings must be less than 60 minutes. Please re-try with shorter file."
        );

      const result = await createVideoSignedUrl({ videoId });

      let presignedPost = result.presignedPost;
      readUrl = result.readUrl;
      let presignedPostFields = presignedPost.fields;

      if (!presignedPost) throw new Error("Error creating upload url");

      var formData = new FormData();
      formData.append("acl", "public-read");

      for (const field in presignedPostFields) {
        formData.append(field, presignedPostFields[field]);
      }

      formData.append("file", file);
      let uploadId = result.uploadId;
      let config = {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (e) => mediaUploadProgress(e, uploadId),
      };

      await axios.post(presignedPost.url, formData, config);

      const updates = {
        status: "webMUploaded",
        videoUrl: readUrl,
      };

      if (recorderName) {
        updates.speakerName = recorderName;
      }

      await editVideo(videoId, updates);

      setRecordStatus("uploaded");

      return readUrl;
    } catch (err) {
      let errorMessage = err.data?.message || err.message;
      throw new Error(errorMessage);
    }
  };

  return [
    recordStatus,
    state.recordingCountdown,
    startRecordingCountdown,
    stopRecording,
    finalVideoBlob,
    reRecord,
    createVideoObject,
    uploadMedia,
    uploadedPercent,
    errorMessage,
  ];
};
